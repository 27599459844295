<template>
  <div>
    <Header :title="isEdit ? '修改问题' : '新增问题'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }">

        <a-form-item label="标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modelRef.title" placeholder="请输入标题"></a-input>
        </a-form-item>

        <a-form-item class="ui-form__item" name="module" label="所属模块" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-select v-model:value="modelRef.module" style="width: 180px;">
            <a-select-option :value="0">请选择所属模块</a-select-option>
            <a-select-option :value="1">模块1</a-select-option>
            <a-select-option :value="2">模块2</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="问题" name="problem" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modelRef.problem" placeholder="请输入问题"></a-input>
        </a-form-item>

        <a-form-item label="回答" name="answer" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input v-model:value="modelRef.answer" placeholder="请输入回答"></a-input>
        </a-form-item>

        <a-form-item label="优先级" name="priority" extra="优先级越高越靠前，不填则为0">
          <a-input v-model:value="modelRef.priority" placeholder="请输入优先级"></a-input>
        </a-form-item>

        <a-form-item label="是否启用" name="isAfterOrder" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-switch v-model:checked="modelRef.isAfterOrder" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>

      </a-form>
    </a-spin>
  </div>
</template>

<script>
// import dayjs from 'dayjs';
import Header from '@/components/header/header.vue';

export default {
  components: {
    Header
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mode1: 'time',
      value: [],
      value1: '',
      loading: false,
      isRead: false,
      organizationList: [],
      cinemaAllList: [],
      modelRef: {
        module: 0,
        title: '',
        problem: '',
        answer: '',
        priority: '',
        pricingLevel: 0,
        isDisabled: false,
        versionType: 1,
        filmType: 1,
        filmList: [],
        hallType: 1,
        hallList: [],
        visualType: 1,
        visualList: [],
        timeList: [],
        pricingSettings: {
          firstMode: 1,
          secondMode: 1
        },
        showList: [],
      },
    }
  },
  watch: {

  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    handleOpenChange1 (open) {
      if (open) {
        this.mode1 = 'time';
      }
    },
    handlePanelChange1 (val, mode) {
      this.mode1 = mode
    },
  }
}
</script>

<style scoped>

</style>