<template>
  <div v-show="!showModal">
    <a-spin :spinning="loading">
      <a-form ref="form" :model="formState" name="form">
        <!-- 搜索栏 -->
        <a-row>

          <a-form-item class="ui-form__item" name="module" label="所属模块">
            <a-select v-model:value="formState.module" placeholder="请选择所属模块" allow-clear style="width: 180px;">
              <a-select-option :value="1">猜你想问</a-select-option>
              <a-select-option :value="2">模块1</a-select-option>
              <a-select-option :value="3">模块2</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" name="state" label="状态">
            <a-select v-model:value="formState.state" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="1">启用</a-select-option>
              <a-select-option :value="2">禁用</a-select-option>
            </a-select>
          </a-form-item>

        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['cinema_policy_pricing_add']" type="primary" @click="onAdd">新增</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right">
            <a-button
              style="margin-right: 10px"
              type="primary"
              html-type="submit"
            >搜索</a-button
            >
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
                 :scroll="{ x: 950 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div>
                      <a-menu-item>
                        启用/禁用
                      </a-menu-item>
                    </div>
                    <div>
                      <a-menu-item>
                        删除
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>
    </a-spin>

  </div>
  <temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
import temp from './temp.vue'
import {
  Icon
} from '@/components/icon/icon.js';
export default {
  components: {
    Icon,
    temp
  },
  data() {
    return {
      showModal: false,
      isEdit: false,
      isSee: false,
      id: 0,
      loading: false,
      showAll: false,
      formState: {},
      columns: [{
        title: '标题',
        dataIndex: 'title',
        width: 100
      }, {
        title: '所属模块',
        dataIndex: 'module',
        width: 100
      }, {
        title: '优先级',
        dataIndex: 'priority',
        width: 100
      }, {
        title: '状态',
        dataIndex: 'state',
        width: 100
      }, {
        title: '操作',
        key: 'action',
        width: 100
      }],
      list: [{
        key: 1,
        title: '标题1',
        module: '猜你想问',
        priority: '1',
        state: '启用',
      }, {
        key: 2,
        title: '标题2',
        module: '猜你想问',
        priority: '1',
        state: '启用',
      }, {
        key: 3,
        title: '标题3',
        module: '猜你想问',
        priority: '1',
        state: '启用',
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
    }
  },
  created() {
  },
  methods: {
    reset() {
      this.$refs.form.resetFields();
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
      }
      this.time = [];
      this.time1 = [];
      this.onSearch();
    },
    onAdd() {
      this.id = 0;
      this.isEdit = false;
      this.isSee = false;
      this.showModal = true;
    },
    onEdit(item) {
      this.id = item.id;
      this.isEdit = true;
      this.isSee = false;
      this.showModal = true;
    },
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.$nextTick(() => {
          this.getData();
        })
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
